<template >
  <div style="height: inherit" ref="check">

    <div class="body-content-overlay" />

    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="title" :placeholder="sortType" class="search-product" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>

      </b-row>
      <b-progress v-if="precentage !== 0" :value="precentage" max="100" />
      <div class="misc-inner p-2 p-sm-3" v-if="precentage !== 0">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            يرجى عدم الخروج قبل تأكيد الارسال 🕵🏻‍♀️
          </h2>
        </div>
      </div>
    </div>

    <section :class="itemView">
      <b-card v-for="product in items" :key="product.id" class="ecommerce-card" no-body>
        <b-col md="12" lg="12">
          <b-card :img-src="getImage(product.image)" img-alt="Card image cap" img-top :title="product.title">

            <b-card-text>
              {{ product.details }}
            </b-card-text>
            <b-card-text v-if="!isLoged">
              لملئ هذه الاستمارة يتطلب التسجيل في الموقع
              <span>
                <b-link :to="{ name: 'auth-login' }">تسجيل الدخول </b-link>
              </span>
            </b-card-text>
            <div v-if="isLoged">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-info" @click=showModel(product)>
                ملئ الاستمارة
              </b-button>
              <b-card-text v-if="product.isnuc && !isNuc">
                لملئ هذه الاستمارة يتطلب التسجيل في الموقع بايميل رسمي الخاص بالكلية و المنتهي ب "@nuc.edu.iq" و للحصول
                عليه يجب ارسال طلب ايميل جامعي
                <span>
                  <b-link :to="{ name: 'auth-login' }">تسجيل الدخول </b-link>
                </span>
              </b-card-text>
              <b-button v-if="!product.isnuc && !isNuc" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-info"
                @click=showModel(product)>
                ملئ الاستمارة
              </b-button>
            </div>

          </b-card>
        </b-col>
      </b-card>
    </section>
    <b-modal ref="model-a-w6" ok-title="ارسال الطلب" :ok-disabled="dirform.phone.length!=12||dirform.title==null||dirform.depart_id==null||dirform.stage==null||dirform.type==null||dirform.payment==null||dirform.files.length==0" centered size="lg" title="استمارة مباشرة الدراسة" @ok="aDM"
      @hidden="rDF()">
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>القسم العلمي :</h3>
              </label>
              <v-select v-model="dirform.depart" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="departments" placeholder="اختر القسم" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>المرحلة الدراسية :</h3>
              </label>
              <v-select v-model="dirform.stage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="stages" placeholder="اختر المرحلة" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="input-valid1">
                <h3>نوع الدراسة :</h3>
              </label>
              <v-select v-model="dirform.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="types" placeholder="اختر نوع الدراسة" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="phone">
              <h3>رقم الواتساب :</h3> <h3 style="color: red;" v-if = "this.dirform.phone.startsWith('0')">اكتب الرقم بدون الصفر مثلا (7700000000)</h3>
            </label>
            <cleave id="phone" v-model="dirform.phone" class="form-control" :raw="false" :options="options.phone" dir="ltr"
                  placeholder="770000000" />          
            </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>الاسم الرباعي للطالب :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="dirform.title" placeholder="اكتب الاسم الرباعي ..." />
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>وصل الهوية الجامعية:</h3>
            </label>
            <b-form-file placeholder="اختر وصل القسط الدراسي..." drop-placeholder="اختر وصل القسط الدراسي..."
              v-model="dirform.payment" @change="selectFileFordirformpayment" />
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>جميع وصولات الاقساط الدراسية للمرحلة الحالية(ممكن رفع اكثر من صورة):</h3>
            </label>
            <b-form-file multiple placeholder="اختر وصل القسط الدراسي..." drop-placeholder="اختر وصل القسط الدراسي..."
              v-model="dirform.files" @change="selectFileFordirformpayment" />
          </b-col>
          <b-col md="12" style="margin: 10px;">
            <h4 style="color: red;">جميع المعلومات اعلاه مطلوبة و بخلافه سيتم رفض الطلب *</h4>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal ref="model-a-w" :ok-disabled="erform.title==null||erform.depart==null||erform.stage==null||erform.email==null||erform.file==null" ok-title="ارسال الطلب" centered size="lg" title="استمارة الايميل الجامعي" @ok="aNM"
      @hidden="hideModel()" >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>القسم العلمي :</h3>
              </label>
              <v-select v-model="erform.depart" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="departments" placeholder="اختر القسم" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>المرحلة الدراسية :</h3>
              </label>
              <v-select v-model="erform.stage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="stages" placeholder="اختر المرحلة" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">
              <h3>الاسم الرباعي للطالب :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="erform.title" placeholder="اكتب الاسم الرباعي ..." />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">
              <h3>الايميل فعال(سيتم ارسال معلومات الايميل الجامعي عن طريقه) :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="erform.email"
              placeholder="اكتب ايميل فعال (سيتم ارسال الايميل و الباسورد عن طريقه) ..." />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">
              <h3>وصل القسط الدراسي:</h3>
            </label>
            <b-form-file placeholder="اختر وصل القسط الدراسي..." drop-placeholder="اختر وصل القسط الدراسي..."
              v-model="erform.file" @change="selectFileForForm" />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal ref="model-a-w2" :ok-disabled="enform.phone.length!=12||enform.depart_id==null||enform.stage==null||enform.type==null||enform.to==null||enform.file==null"  ok-title="ارسال الطلب" centered size="lg" title="استمارة تأييد استمرار بالدراسة" @ok="aNMEN"
      @hidden="renF()">
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>القسم العلمي :</h3>
              </label>
              <v-select v-model="enform.depart" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="departments" placeholder="اختر القسم" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>المرحلة الدراسية :</h3>
              </label>
              <v-select v-model="enform.stage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="stages" placeholder="اختر المرحلة" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="input-valid1">
                <h3>نوع الدراسة :</h3>
              </label>
              <v-select v-model="enform.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="types" placeholder="اختر نوع الدراسة" />
            </b-form-group>
          </b-col>
          
          <b-col md="6">
            <label for="phone">
              <h3>رقم الواتساب :</h3> <h3 style="color: red;" v-if = "this.enform.phone.startsWith('0')">اكتب الرقم بدون الصفر مثلا (7700000000)</h3>
            </label>
            <cleave id="phone" v-model="enform.phone" class="form-control" :raw="false" :options="options.phone" dir="ltr"
                  placeholder="770000000" />          
            </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>الاسم الرباعي للطالب :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="enform.title" placeholder="اكتب الاسم الرباعي ..." />
          </b-col>

          <b-col md="6">
            <label for="input-valid1">
              <h3>يعنون الى :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="enform.to" placeholder="يعنون الى ..." />
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3> وصل القسط الدراسي:</h3>
            </label>
            <b-form-file placeholder="اختر صورة لوصل القسط الدراسي..." drop-placeholder="اختر صورة لوصل القسط الدراسي..."
              v-model="enform.file" @change="selectFileForenForm" />
          </b-col>
          <b-col md="12" style="margin: 10px;">
            <h4 style="color: red;">جميع المعلومات اعلاه مطلوبة و بخلافه سيتم رفض الطلب *</h4>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal ref="model-a-w3" :ok-disabled="cform.phone.length!=12||cform.depart_id==null||cform.stage==null||cform.type==null||cform.title==null||cform.blood==null||cform.address==null||cform.payment==null||cform.file==null" ok-title="ارسال الطلب" centered size="lg" title="استمارة الهوية الجامعية" @ok="aCM"
      @hidden="rCF()">
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>القسم العلمي :</h3>
              </label>
              <v-select v-model="cform.depart" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="departments" placeholder="اختر القسم" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1">
                <h3>المرحلة الدراسية :</h3>
              </label>
              <v-select v-model="cform.stage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="stages" placeholder="اختر المرحلة" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="input-valid1">
                <h3>نوع الدراسة :</h3>
              </label>
              <v-select v-model="cform.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="types" placeholder="اختر نوع الدراسة" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>الاسم الرباعي للطالب :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="cform.title" placeholder="اكتب الاسم الرباعي ..." />
          </b-col>
          <b-col md="6">
            <label for="phone">
              <h3>رقم الواتساب :</h3> <h3 style="color: red;" v-if = "this.cform.phone.startsWith('0')">اكتب الرقم بدون الصفر مثلا (7700000000)</h3>
            </label>
            <cleave id="phone" v-model="cform.phone" class="form-control" :raw="false" :options="options.phone" dir="ltr"
                  placeholder="770000000" />          
            </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>صنف الدم :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="cform.blood" placeholder="O+ ..." />
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>عنوان السكن (المحافظة فقط) :</h3>
            </label>
            <b-form-input id="input-valid1" v-model="cform.address" placeholder="مثلا بغداد  ..." />
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>وصل الهوية</h3>
            </label>
            <b-form-file placeholder="اختر وصل الهوية..." drop-placeholder="اختر وصل الهوية..." v-model="cform.payment"
              @change="selectFileForcFormpayment" />
          </b-col>
          <b-col md="6">
            <label for="input-valid1">
              <h3>الصورة الشخصية (ذات خلفية بيضة):</h3>
            </label>
            <b-form-file placeholder="اختر الصورة الشخصية..." drop-placeholder="اختر الصورة الشخصية..."
              v-model="cform.file" @change="selectFileForcForm" />
          </b-col>
          <b-col md="12" style="margin: 10px;">
            <h4 style="color: red;">جميع المعلومات اعلاه مطلوبة و بخلافه سيتم رفض الطلب *</h4>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal ref="model-a-w4" :ok-disabled="pform.phone.length!=12||pform.title==null||pform.depart_id==null||pform.stage==null||pform.type==null||pform.state==null||pform.lectures.length==0||pform.files.length== 0" ok-title="ارسال الطلب" centered size="lg" title="استمارة تاجيل الامتحانات" @ok="aPM"
      @hidden="rPF()">
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <!-- <h2>تم اغلاق استمارة التأجيل لانتهاء الموعد المحدد لها </h2> -->
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1"><h3>القسم العلمي : </h3></label>
              <v-select v-model="pform.depart" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="departments" placeholder="اختر القسم" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="input-valid1"><h3>المرحلة الدراسية :</h3></label>
              <v-select v-model="pform.stage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="stages" placeholder="اختر المرحلة" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group>
              <label for="basic-password"><h3>المواد الدراسية </h3></label>
              <v-select multiple v-model="pform.lectures" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="subject"
                :options="lectures" placeholder="اختر المواد المراد تأجيلها" />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="lectures.length>0">
            <label for="basic-password"><h3> </h3></label>
            <b-form-group>
            <b-form-checkbox-group 
              id="checkbox-group-2"
              name="flavour-2"
              class="demo-inline-spacing"
              v-model="takeAll"
            >
              <b-form-checkbox :value="true"><h4>تأجيل جميع المواد </h4> </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-valid1"><h3>الاسم الرباعي للطالب :</h3></label>
            <b-form-input id="input-valid1" v-model="pform.title" placeholder="اكتب الاسم الرباعي ..." />
          </b-col>
          <b-col md="6">
            <label for="phone">
              <h3>رقم الواتساب :</h3> <h3 style="color: red;" v-if = "this.pform.phone.startsWith('0')">اكتب الرقم بدون الصفر مثلا (7700000000)</h3>
            </label>
            <cleave id="phone" v-model="pform.phone" class="form-control" :raw="false" :options="options.phone" dir="ltr"
                  placeholder="770000000" />          
            </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="basic-password"><h3>سبب التاجيل</h3></label>
              <v-select v-model="pform.state" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="full_name"
                 :options="status" placeholder="سبب التاجيل" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="input-valid1"><h3>نوع الدراسة :</h3></label>
              <v-select v-model="pform.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="types" placeholder="اختر نوع الدراسة" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">
              <h3> صورة كتاب رسمي (كتاب من المستشفى او طبيب او محضر التوقيف او شهادة وفاة) لاثبات سبب التاجيل مصدق من الجهات المسؤولة :</h3>
              <h3 style="color: red;"> ملاحظة/ صورة غير رسمية لن يتم تاجيل الامتحان </h3>
            </label>
            <b-form-file multiple placeholder="ممكن ارسال اكثر من صورة..." drop-placeholder="ممكن ارسال اكثر من صورة..."
              v-model="pform.files" @change="selectFileFordirformpayment" />
          </b-col>
          <b-col style="margin-top: 20px;">
            <p>عمـــــــــــــــــــــــــــلاً بأحكـــــــــــــــــــــــــــــام المادة ( 17 ) من قانون التعليم العالي الأهلي رقم ( 25 ) لسنة 2016.. والمـــــــــــادة ( 10 ) من التعليمات الامتحانية رقم ( 134 ) لسنة 2000 وتعديلاتها.. والتي تنص: للطالب المشاركة بامتحانات الدور الثاني إذا كان عدم مشاركته في امتحانات الدور الأول بعذر مشروع يقره مجلس الكلية أو المعهد على أن يعزز ذلك بوثائق رسمية وفي إحدى الحالات الآتية: </p>
          <p>1) المرض المفاجيء.<br>        2) وفاة أحد إقربائه من الدرجة الأولى.<br>         3) حوادث الدهس.  <br>       4) التوقيف المفاجيء. </p>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-waiting"
      ok-only
      ok-variant="success"
      ok-title="اغلاق"
      :ok-disabled="precentage !== 0"
      modal-class="modal-success"
      centered
      @ok="submited"
      title="ارسال الطلب"
    >
    <b-card-text v-if="isSubmitted === true &&precentage===0">
       شكرا جزيلا <br> تم ارسال الطلب بنجاح<br>عند اكتمال الطلب المقدم سيتم التواصل معك عن طريق الواتساب او الايميل
      </b-card-text>
      <b-card-text v-if="precentage != 0">
        يرجى عدم الخروج قبل تأكيد الارسال 🕵🏻‍♀️
      </b-card-text>
      <b-card-text v-if="isSubmitted==false">
       فشل في ارسال الطلب و ذلك بسبب قطع في الانترنت او عدم ملئ الاستمارة بصورة كاملة
      </b-card-text>
        <b-progress
        v-if="precentage !== 0" :value="precentage"
          max="100"
          precision="2"
          show-value
          :variant="orderstate"
          class="progress-bar-danger"
        />
    </b-modal>
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number align="center"
            last-number prev-class="prev-item" next-class="next-item" @change="checkCurrent">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar :filters="filters" :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio, BForm,
  BRow, BProgress,
  BCol,BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BFormInput, BFormFile,
  BCard,BFormCheckboxGroup,
  BCardBody, BFormGroup,
  BLink, BAlert,
  BImg, BFormTextarea,
  BCardText, BFormRow,
  BButton, BModal,
  VBModal, BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BPagination,
} from "bootstrap-vue";
import { filesUrl } from "@/main.js"; import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import emailjs from '@emailjs/browser';
import { SID, TID2, TID, AID } from "@/main.js";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api"; import vSelect from "vue-select";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import useAppCustomizer from "@core/layouts/components/app-customizer/useAppCustomizer";
import ShopLeftFilterSidebar from "./sidebar.vue"; import store from '@/store';
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useECommerceShop";
import { useEcommerceUi } from "./useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BDropdownItem,
    BFormRadioGroup, BFormFile,
    BFormRadio, BBadge,
    BRow,BFormCheckboxGroup,
    BCol, BFormTextarea,
    BInputGroup, BProgress,
    BInputGroupAppend,
    BFormInput,BFormCheckbox,
    BCard, BFormRow,
    BCardBody,
    BModal, vSelect, BFormGroup,
    VBModal, BForm, Cleave,
    BLink,
    BImg, BAlert,
    BCardText,
    BButton,
    BPagination,
    // SFC
    ShopLeftFilterSidebar,
  },

  data() {
    return {
      takeAll:false,
      cOR:"nuc-",
      orderstate:"danger",
      isSubmitted :null,
      cert_ar_title: "بكالوريوس",
      cert_en_title: "Bachelor's",
      precentage: 0,
      sortType: "ابحث عن الخدمة المطلوبة ...",
      items: [],
      status: [
        'المرض المفاجئ',
        'وفاة احد الاقرباء من الدرجة الاولى',
        'جادث دهس',
        'التوقيف المفاجئ',
        'اداء مناسك الحج'
      ],
      yesORNo: [
        'yes',
        'no'
      ],
      totalCount: 0,
      lectures: [],
      levels: [],
      apps: [
        'Whatsapp',
        'Viber',
        'Telegram'
      ],
      department_id: "",
      lectures: [],
      author_id: "",
      category_id: "",
      departments: [],
      stages: [],
      certifacats: [],
      title: "",
      perPage: 12,
      currentPage: 0,
      types: [],
      countries: [],
      p_status: [],
      genders: [
        'ذكر',
        'انثى'
      ],
      title: "",
      form: {
        title: null,
        depart: null,
        stage: null,
        file: null,
      },
      erform: {
        title: null,
        depart: null,
        depart_id: null,
        stage: null,
        file: null,
      },
      enform: {
        title: null,
        depart: null,
        depart_id: null,
        stage: null,
        stage_id: null,
        type: null,
        type_id: null,
        to: null,
        phone: "",
        file: null,
        email: null,
      },
      cform: {
        title: null,
        depart: null,
        depart_id: null,
        stage: null,
        stage_id: null,
        blood: null,
        type_id: null,
        address: null,
        phone: "",
        file: null,
        payment: null,
        email: null
      },
      pform: {
        title: null,
        depart: null,
        depart_id: null,
        stage: null,
        stage_id: null,
        type_id: null,
        lectures: [],
        files:[],
        phone: "",
        state:null,
      },
      sform: {
        certifacat_id: 2,
        department_id: null,
        f_name: "",
        m_name: null,
        g_name: null,
        f_m_name: null,
        m_m_name: null,
        g_m_name: null,
        gender: null,
        country_id: null,
        birthday: null,
        birthday_country_id: null,
        status_id: null,
        passport_no: null,
        passport_expired: null,
        res_country_id: null,
        city: null,
        special_needs: null,
        last_certificate_id: null,
        specialization: null,
        cert_country_id: null,
        university: null,
        year: null,
        researches: null,
        scopus: null,
        orcid: null,
        scholar: null,
        en_level_id: null,
        ar_level_id: null,
        cert_file: null,
        passport_file: null,
        english_file: null,
        proposal_file: null,
        address_country_id: null,
        city: null,
        post_address: null,
        street: null,
        email: null,
        phone: "",
        contact_type: null,
        cert_file: null,
        passport_file: null,
        english_file: null,
        proposal_file: null,
        cv_file: null
      },

      isLoged: true,
      isNuc: true,
      date: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      timer: {
        seconds: 100,
        settimer: null
      },
      emailMessage: {
        headerMessage: null,
        bodyMessage: null,
        code: null,
        from_name: null,
        to_email: null,
      },
      dirform: {
        phone:"",
        title: null,
        depart: null,
        depart_id: null,
        stage: null,
        stage_id: null,
        blood: null,
        type_id: null,
        payment: null,
        files: [],
        email: null
      },
      options:{
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      res_lectures:[],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        user_id: "",
        teacher_id: "",
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      c: "",
      code: null,
      toEmail: false,
      toName: false,
      subject: null,
      toID: null,
      m: null,
      toUserID: null,

    };
  },
  setup() {
    const {
      // Layout Type
      layoutType,
      layoutTypeOptions } = useAppCustomizer()
    return {
      // Layout Type
      layoutType,
      layoutTypeOptions,
    }
  },
  created() {
    if (localStorage.getItem("NisourToken")) {
      this.isLoged = true;
    } else {
      this.isLoged = true
    }
  },
  mounted() {
    this.gS();
    this.gSM();
    this.gSM1();
    this.gSM2();
    this.gSM7();
    this.gSM8();
    this.gSM9();
    this.gSM10();
  },
  watch: {
    "erform.depart": function (val) {
      this.gSM1();
    },
    takeAll: function (val) {
      if(this.takeAll){
        this.pform.lectures = this.lectures
      }else{
        this.pform.lectures = []
      }
    },
    "enform.depart": function (val) {
      this.gSM3();
    },
    "cform.depart": function (val) {
      this.gSM4();
    },
    "pform.depart": function (val) {
      this.gSM5();
    },
    "dirform.depart": function (val) {
      this.gSM11();
    },
    "pform.stage": function (val) {
      this.gSM6();
    },
    title: function (val) {
      this.gS();
    },
    precentage: function (val) {
      if(this.precentage<=40){
        this.orderstate = "danger"
      }else if(this.precentage>40&&this.precentage<=70){
        this.orderstate = "warning"
      }else{
        this.orderstate = "success"
      }
    },
    "timer.seconds": function (val) {
      if (val < 0) {
        this.stopTimer();
      }
    },
  },
  methods: {
    checkCode() {
      if (this.c == this.code) {
        this.aSM()
      } else {
        this.makeToast(
          "warning",
          "الكود خطأ",
          `الكود المرسل غير متطابق`
        );
      }

    },
    sendCode() {
      this.timer.settimer = setInterval(() => {
        this.timer.seconds--;
      }, 1000)
      this.code = null;
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 10; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.code = result
      this.emailMessage.headerMessage = "هذه الايميل لتفعيل الحساب",
        this.emailMessage.bodyMessage = "يرجى نقل الكود ادناه لاتمام عملية التسجيل و ارسال الاستمارة",
        this.emailMessage.code = this.code,
        this.emailMessage.from_name = "كلية النسور الجامعة",
        this.emailMessage.to_email = "ahmed.itm@nuc.edu.iq",
        this.sendEmail(this.emailMessage.headerMessage, this.emailMessage.bodyMessage, this.emailMessage.code, this.emailMessage.from_name, this.emailMessage.to_email,);

    },
    submited(){
      this.isSubmitted = null
    },
    sendEmail(h, b, c, f, t) {
      emailjs.send(SID, TID2, {
        headerMessage: h,
        bodyMessage: b,
        code: c,
        from_name: f,
        to_email: t
      }, AID)
        .then((result) => {
          this.makeToast(
            "success",
            "Notification Sender",
            `The notification has been sent successfully`
          );
        }, (error) => {
          console.log('FAILED...', error.text);
        });
    },
    waiting() {
      this.$bvModal.show('modal-waiting');
    },
    stopTimer() {
      clearInterval(this.timer.settimer)
      this.timer.seconds = 100
      this.code = null
      this.c = null
    },
    async gSM() {
      await this.axios
        .get(
          `since/deparment/clint?take=${this.perPage}&skip=${this.currentPage - 1
          }&select=true&type=5`
        )
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
    },
    async gSM1() {
      this.stages = [];
      this.erform.stage == "";
      if (isNaN(this.erform.depart)) {
        this.erform.depart_id = this.erform.depart["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.erform.depart_id}?select=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM2() {
      this.types = [];
      await this.axios
        .get(
          `typesofstudy`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
        )
        .then((res) => {
          this.types = [];
          this.types = res.data.items;
        })
    },

    async gSM3() {
      this.stages = [];
      this.enform.stage == "";
      if (isNaN(this.enform.depart)) {
        this.enform.depart_id = this.enform.depart["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.enform.depart_id}?select=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM4() {
      this.stages = [];
      this.cform.stage == "";
      if (isNaN(this.cform.depart)) {
        this.cform.depart_id = this.cform.depart["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.cform.depart_id}?select=true&check=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM5() {
      this.stages = [];
      this.pform.stage == "";
      if (isNaN(this.pform.depart)) {
        this.pform.depart_id = this.pform.depart["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.pform.depart_id}?select=true&check=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM11() {
      this.stages = [];
      this.dirform.stage == "";
      if (isNaN(this.dirform.depart)) {
        this.dirform.depart_id = this.dirform.depart["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.dirform.depart_id}?select=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM6() {
      this.lectures = [];
      await this.axios
        .get(`exams?take=10&skip=0&stage_id=${this.pform.stage.id}`)
        .then((res) => {
          this.lectures = [];
          this.lectures = res.data.items;
        })
    },
    async gSM7() {
      this.lectures = [];
      await this.axios
        .get(`certificate?take=10&skip=0`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.certifacats = [];
          this.certifacats = res.data.items;
        })
    },
    async gSM8() {
      this.lectures = [];
      await this.axios
        .get(`country?take=1000&skip=0&type_id=5`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.countries = [];
          this.countries = res.data.items;
        })
    },
    async gSM9() {
      this.lectures = [];
      await this.axios
        .get(`status?take=1000&skip=0`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.p_status = [];
          this.p_status = res.data.items;
        })
    },
    async gSM10() {
      this.lectures = [];
      await this.axios
        .get(`levels?take=1000&skip=0`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.levels = [];
          this.levels = res.data.items;
        })
    },
    sendNote(m, n, e) {
      const fromName = localStorage.getItem("NisourUserName");
      const fromEmail = localStorage.getItem("NisourEmail");
      emailjs
        .send(
          SID,
          TID,
          {
            from_name: fromName,
            to_name: n,
            message: m,
            to_email: e,
            reply_to: fromEmail,
          },
          AID
        )
        .then(
          (result) => {
            this.toName = "";
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
    toLogin() {
      this.$router.push("/login");
    },
    selectFileForForm(e) {
      this.erform.file = "";
      this.erform.file = e.target.files[0];
    },
    selectFileForenForm(e) {
      this.enform.file = "";
      this.enform.file = e.target.files[0];
    },
    selectFileForcFormpayment(e) {
      this.cform.payment = "";
      this.cform.payment = e.target.files[0];
    },
    selectFileForcForm(e) {
      this.cform.file = "";
      this.cform.file = e.target.files[0];
    },
    selectFileForpformcertifacates(e) {
      this.pform.cert_file = "";
      this.pform.cert_file = e.target.files[0];
    },
    selectFileForpassport_file(e) {
      this.pform.passport_file = "";
      this.pform.passport_file = e.target.files[0];
    },
    selectFileForenglish_file(e) {
      this.pform.english_file = "";
      this.pform.english_file = e.target.files[0];
    },
    selectFileForproposal_file(e) {
      this.pform.proposal_file = "";
      this.pform.proposal_file = e.target.files[0];
    },
    selectFileForcv_file(e) {
      this.pform.cv_file = "";
      this.pform.cv_file = e.target.files[0];
    },
    showModel(x) {
      this.cOR += x.id+"-"
      this.$refs[x.model].show()
      console.log(this.cOR)
    },
    hideModel() {
      this.erform.title = null
      this.erform.email = null
      this.erform.depart = null
      this.erform.depart_id = null
      this.erform.file = null
      this.erform.stage = null
      this.cOR = "nuc-"
    },
    clicked() {
      useAppCustomizer().layoutType['value'] = "vertical"
      // store.commit('app-config/UPDATE_CONTENT_WIDTH', 'vertical')
    },
    reClicked() {
      useAppCustomizer().layoutType['value'] = "horizontal"
      // store.commit('app-config/UPDATE_CONTENT_WIDTH', 'vertical')
    },
    changeType(id) {
      this.updateType(id)
    },
    checkCurrent() {
      setTimeout(() => this.gS(), 1000);
    },
    async gS() {
      await this.axios
        .get(
          `services?take=${this.perPage}&skip=${this.currentPage - 1}&title=${this.title}`
        )
        .then((res) => {
          this.items = [];
          this.totalCount = "";
          this.totalCount = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    async aNM() {
      this.scrollToElement();
      this.waiting()
      let data = new FormData();
      data.append("title", this.erform.title);
      data.append("depart", this.erform.depart.title);
      data.append("stage", this.erform.stage.title);
      data.append("email", this.erform.email);
      data.append("image", this.erform.file);
      await this.axios
        .post(`emails`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            console.log(this.cOR)
            this.cOR+=`${res.data.id}`
            console.log(this.cOR)
            this.isSubmitted =true
            this.notifi.route = "em-notif";
            this.notifi.title = "طلب ايميل جامعي ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "emails";
            this.notifi.role_id = 1;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد طلب ايميل جامعي جديد`;
            this.notifi.bg = "bg-success";
            this.sendNoti();
            this.toEmail = 'info@nuc.edu.iq',
              this.toName = 'الاعلام و تكنولوجيا المعلومات',
              this.toID = 0,
              this.toUserID = 33,
              this.subject = "طلب ايميل جامعي",
              this.m = ``;
            this.sendNote(this.m, this.toName, this.toEmail);

            this.makeToast(
              "success",
              "تم ارسال الطلب",
              "ستستلم الايميل الجامعي على الايميل المرفق في الطلب خلال يومين"
            );
          }
          this.rF();
          this.precentage = 0
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    async aNMEN() {
      this.waiting()
      this.scrollToElement();
      let data = new FormData();
      data.append("title", this.enform.title);
      data.append("department_id", this.enform.depart_id);
      data.append("stage_id", this.enform.stage.id);
      data.append("type_id", this.enform.type.id);
      data.append("to", this.enform.to);
      this.enform.phone = this.enform.phone.replace(/\s/g, '');
      data.append("phone", `+964${this.enform.phone}`);
      data.append("image", this.enform.file);
      data.append("email", this.enform.email);
      await this.axios
        .post(`endorsement`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.notifi.route = "end-notif";
            this.notifi.title = "طلب تأييد استمرارية بالدراسة ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "endorsements";
            this.notifi.role_id = 15;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد طلب استمرارية بالدراسة جديد`;
            this.notifi.bg = "bg-success";
            this.sendNoti();
            this.toEmail = 'registration@nuc.edu.iq ',
              this.toName = 'قسم التسجيل',
              this.toID = 0,
              this.toUserID = 33,
              this.subject = "طلب تاييد استمرار بالدراسة ",
              this.m = `السيد مدير التسجيل المحترم: نود اعلامكم بورود طلب تاييد بالدراسة جديد`;
            this.sendNote(this.m, this.toName, this.toEmail);


            this.makeToast(
              "success",
              "تم ارسال الطلب",
              "سيتم التواصل معك عن طريق الايميل في حال اكتمال الطلب او وجود مشكلة"
            );
          }
          this.renF();
          this.precentage = 0
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
      this.renF();
    },
    async aCM() {
      this.waiting()
      this.scrollToElement();
      let data = new FormData();
      data.append("title", this.cform.title);
      data.append("department_id", this.cform.depart_id);
      data.append("stage_id", this.cform.stage.id);
      data.append("type_id", this.cform.type.id);
      data.append("blood", this.cform.blood);
      data.append("address", this.cform.address);
      this.cform.phone = this.cform.phone.replace(/\s/g, '');
      data.append("phone", `+964${this.cform.phone}`);
      data.append("payment", this.cform.payment);
      data.append("image", this.cform.file);
      data.append("email", this.cform.email);
      await this.axios
        .post(`card`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.isSubmitted =true
            this.notifi.route = "ca-notif";
            this.notifi.title = "طلب هوية جامعية ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "cards";
            this.notifi.role_id = 15;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد طلب هوية جامعية جديد`;
            this.notifi.bg = "bg-success";
            this.sendNoti();

            this.toEmail = 'registration@nuc.edu.iq ',
              this.toName = 'قسم التسجيل',
              this.toID = 0,
              this.toUserID = 33,
              this.subject = "طلب هوية جامعية ",
              this.m = `السيد مدير التسجيل المحترم: نود اعلامكم بورود طلب هوية جامعية جديد`;
            this.sendNote(this.m, this.toName, this.toEmail);
            this.makeToast(
              "success",
              "تم ارسال الطلب",
              "سيتم التواصل معك عن طريق النظام في حال اكتمال الطلب او وجود مشكلة"
            );
          }
          this.rCF();
          this.precentage = 0
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
      this.rCF();
    },
    rDF() {
      this.dirform.title = null,
        this.dirform.depart = null,
        this.dirform.depart_id = null,
        this.dirform.stage = null,
        this.dirform.stage_id = null,
        this.dirform.type = null,
        this.dirform.payment = null,
        this.dirform.files = [],
        this.dirform.email = null
        this.dirform.phone = ""
    },
    async aDM() {
      this.waiting()
      this.scrollToElement();
      let data = new FormData();
      data.append("title", this.dirform.title);
      data.append("department_id", this.dirform.depart_id);
      data.append("stage_id", this.dirform.stage.id);
      data.append("type_id", this.dirform.type.id);
      data.append("payment", this.dirform.payment);
      data.append("email", "ahmed@nuc.edu.iq");
      this.dirform.phone = this.dirform.phone.replace(/\s/g, '');
      data.append("phone", `+964${this.dirform.phone}`);
      if (this.dirform.files.length > 0) {
        this.dirform.files.forEach((element) =>
          data.append("images[]", element)
        );
      }
      await this.axios
        .post(`direct`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
            this.isSubmitted =true
          this.makeToast(
            "success",
            "تم ارسال الطلب",
            "سيتم التواصل معك عن طريق الايميل في حال اكتمال الطلب او وجود مشكلة"
          );
          this.rDF();
          this.precentage = 0
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
      this.rdF();
    },
    async aPM() {
      this.waiting()
      this.scrollToElement();
      let data = new FormData();
      data.append("title", this.pform.title);
      data.append("department_id", this.pform.depart_id);
      data.append("stage_id", this.pform.stage.id);
      data.append("type_id", this.pform.type.id);
      data.append("state", this.pform.state);
      this.pform.phone = this.pform.phone.replace(/\s/g, '');
      data.append("phone", `+964${this.pform.phone}`);
      this.pform.lectures.forEach((element) => data.append("lectures[]", element.id));
      if (this.pform.files.length > 0) {
        this.pform.files.forEach((element) =>
          data.append("images[]", element)
        );
      }
      await this.axios
        .post(`postpone`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.isSubmitted =true
            this.notifi.route = "po-notif";
            this.notifi.title = "طلب تأجيل امتحان ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "cards";
            this.notifi.role_id = 15;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد طلب تأجيل امتحان جديد`;
            this.notifi.bg = "bg-success";
            this.sendNoti();

            this.toEmail = 'registration@nuc.edu.iq ',
              this.toName = 'قسم التسجيل',
              this.toID = 0,
              this.toUserID = 33,
              this.subject = "طلب تاجيل الامتحان ",
              this.m = `السيد مدير التسجيل المحترم: نود اعلامكم بورود طلب تأجيل امتحان جديد`;
            this.sendNote(this.m, this.toName, this.toEmail);

            this.makeToast(
              "success",
              "تم ارسال الطلب",
              "سيتم التواصل معك عن طريق النظام في حال اكتمال الطلب او وجود مشكلة"
            );
          }
          this.rPF();
          this.precentage = 0
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
      this.rPF();
    },

    async aSM() {
      let data = new FormData();
      data.append("certificate_id", this.sform.certifacat_id.id);
      data.append("department_id", this.sform.department_id.id);
      data.append("f_name", this.sform.f_name);
      data.append("m_name", this.sform.m_name);
      data.append("family_name", this.sform.g_name);
      data.append("f_m_name", this.sform.f_m_name);
      data.append("m_m_name", this.sform.m_m_name);
      data.append("g_m_name", this.sform.g_m_name);
      data.append("gender", this.sform.gender);
      data.append("country_id", this.sform.country_id.id);
      data.append("birthday", this.sform.birthday);
      data.append("birthday_country_id", this.sform.birthday_country_id.id);
      data.append("status_id", this.sform.status_id.id);
      data.append("passport_no", this.sform.passport_no);
      data.append("passport_expired", this.sform.passport_expired);
      data.append("res_country_id", this.sform.res_country_id.id);
      data.append("city", this.sform.city);
      data.append("special_needs", this.sform.special_needs);
      data.append("last_certificate_id", this.sform.last_certificate_id.id);
      data.append("specialization", this.sform.specialization);
      data.append("cert_country_id", this.sform.cert_country_id.id);
      data.append("university", this.sform.university);
      data.append("year", this.sform.year);
      data.append("researches", this.sform.researches);
      data.append("scopus", this.sform.scopus);
      data.append("orcid", this.sform.orcid);
      data.append("scholar", this.sform.scholar);
      data.append("en_level_id", this.sform.en_level_id.id);
      data.append("ar_level_id", this.sform.ar_level_id.id);
      data.append("cert_file", this.sform.cert_file);
      data.append("passport_file", this.sform.passport_file);
      data.append("english_file", this.sform.english_file);
      data.append("proposal_file", this.sform.proposal_file);
      data.append("address_country_id", this.sform.address_country_id.id);
      data.append("city", this.sform.city);
      data.append("post_address", this.sform.post_address);
      data.append("street", this.sform.street);
      data.append("email", this.sform.email);
      data.append("phone", this.sform.phone);
      data.append("contact_type", this.sform.contact_type);
      data.append("cv_file", this.sform.cv_file);
      await this.axios
        .post(`scholarship`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.notifi.route = "sc-notif";
            this.notifi.title = "طلب تقديم على المنحة ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "cards";
            this.notifi.role_id = 15;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد طلب تقديم على المنحة جديد`;
            this.notifi.bg = "bg-success";
            this.sendNoti();

            this.toEmail = 'registration@nuc.edu.iq ',
              this.toName = 'قسم التسجيل',
              this.toID = 0,
              this.toUserID = 33,
              this.subject = "طلب تقديم على منحة الاجانب ",
              this.m = `السيد مدير التسجيل المحترم: نود اعلامكم بورود طلب تقديم على منحة الاجانب جديد`;
            this.sendNote(this.m, this.toName, this.toEmail);

            if (this.$i18n.locale == 'ar') {
              this.makeToast(
                "success",
                "تم ارسال الطلب",
                "سيتم التواصل معك عن طريق الايميل في حال اكتمال الطلب او وجود مشكلة"
              );
            } else {
              this.makeToast(
                "success",
                "request sent",
                "You will be contacted via e-mail if the request is complete or there is a problem."
              );
            }
          }
          this.renF();
          this.precentage = 0
        })
        .catch((e) => {
          this.isSubmitted = false
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "فشل الارسال",
              "انت لا تملك هذه الصلاحية"
            );
          } else {
            this.errorToast();
          }
        });
      this.renF();
    },
    scrollToElement() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    },
    rF() {
      this.erform.title = null,
        this.erform.depart = null,
        this.erform.depart_id = null,
        this.erform.stage = null,
        this.erform.file = null
      this.cOR = "nuc-"
    },
    renF() {
      this.enform.title = null,
        this.enform.depart = null,
        this.enform.depart_id = null,
        this.enform.stage = null,
        this.enform.stage_id = null,
        this.enform.type = null,
        this.enform.to = null,
        this.enform.phone = "",
        this.enform.email = null,
        this.enform.file = null
    },
    rCF() {
      this.cform.title = null,
        this.cform.depart = null,
        this.cform.depart_id = null,
        this.cform.stage = null,
        this.cform.stage_id = null,
        this.cform.blood = null,
        this.cform.type = null,
        this.cform.address = null,
        this.cform.phone = "",
        this.cform.payment = null,
        this.cform.file = null
      this.cform.email = null
    },
    rPF() {
      this.pform.title = null,
        this.pform.depart = null,
        this.pform.depart_id = null,
        this.pform.stage = null,
        this.pform.stage_id = null,
        this.pform.type = null,
        this.pform.phone = "",
        this.pform.lectures = [],
        this.pform.state = null,
        this.pform.files=[]
    },
    rSF() {
      this.sform.certifacat_id = 2,
        this.sform.department_id = null,
        this.sform.f_name = null,
        this.sform.m_name = null,
        this.sform.g_name = null,
        this.sform.f_m_name = null,
        this.sform.m_m_name = null,
        this.sform.g_m_name = null
      this.sform.gender = null,
        this.sform.country_id = null,
        this.sform.birthday = null,
        this.sform.birthday_country_id = null,
        this.sform.status_id = null,
        this.sform.passport_no = null,
        this.sform.passport_expired = null,
        this.sform.res_country_id = null
      this.sform.city = null,
        this.sform.special_needs = null,
        this.sform.last_certificate_id = null,
        this.sform.specialization = null,
        this.sform.cert_country_id = null,
        this.sform.university = null,
        this.sform.year = null,
        this.sform.researches = null
      this.sform.scopus = null,
        this.sform.orcid = null,
        this.sform.scholar = null,
        this.sform.en_level_id = null,
        this.sform.ar_level_id = null,
        this.sform.cert_file = null,
        this.sform.passport_file = null,
        this.sform.english_file = null
      this.sform.proposal_file = null
      this.sform.address_country_id = null,
        this.sform.city = null,
        this.sform.post_address = null,
        this.sform.street = null,
        this.sform.email = null
      this.sform.phone = "",
        this.sform.contact_type = null,
        this.sform.cert_file = null,
        this.sform.passport_file = null,
        this.sform.english_file = null,
        this.sform.proposal_file = null,
        this.sform.cv_file = null
    },
    errorToast() {
      this.makeToast(
        "danger",
        "فشل الارسال",
        "يرجى ملئ جميع الحقول لارسال الطلب بنجاح"
      );
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { products, fetchProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      }).then((response) => {
        products.value = response.data.products;
        totalProducts.value = response.data.total;
      });
    };

    fetchShopProducts();

    watch(
      [filters, sortBy],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.image-book {
  width: 400px;
  height: 300px;
  min-height: 15.85rem;
}
</style>
